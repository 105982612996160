import React from 'react';
import RegistrationFromFacebook from "../pages/RegisterFacebook/RegistrationFromFacebook";
import useAuth from "../hooks/useAuth";

const ContainerRegistrationFromFacebook = () => {
    const {register} = useAuth();
    return (
        <>
            <RegistrationFromFacebook register={register}/>
        </>
    );
};

export default ContainerRegistrationFromFacebook;