import React from 'react';

const UserIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.2938 16.5055C17.8423 15.439 17.1871 14.4702 16.3646 13.6531C15.5446 12.8337 14.5733 12.1804 13.5042 11.7293C13.4947 11.7245 13.4851 11.7221 13.4755 11.7173C14.9667 10.6432 15.9361 8.89359 15.9361 6.9196C15.9361 3.6495 13.2792 1 10 1C6.72077 1 4.06387 3.6495 4.06387 6.9196C4.06387 8.89359 5.03328 10.6432 6.52449 11.7197C6.51492 11.7245 6.50534 11.7269 6.49577 11.7317C5.42343 12.1828 4.46121 12.8296 3.63541 13.6555C2.81371 14.4732 2.15857 15.4418 1.70617 16.5079C1.26173 17.5516 1.02204 18.6706 1.00006 19.8043C0.999421 19.8297 1.0039 19.8551 1.01324 19.8788C1.02258 19.9025 1.03658 19.9242 1.05442 19.9424C1.07227 19.9606 1.0936 19.9751 1.11715 19.985C1.1407 19.9949 1.16599 20 1.19155 20H2.62771C2.73303 20 2.8168 19.9165 2.8192 19.8138C2.86707 17.9711 3.60908 16.2454 4.92078 14.9373C6.27795 13.5839 8.08033 12.8392 10 12.8392C11.9197 12.8392 13.722 13.5839 15.0792 14.9373C16.3909 16.2454 17.1329 17.9711 17.1808 19.8138C17.1832 19.9188 17.267 20 17.3723 20H18.8085C18.834 20 18.8593 19.9949 18.8829 19.985C18.9064 19.9751 18.9277 19.9606 18.9456 19.9424C18.9634 19.9242 18.9774 19.9025 18.9868 19.8788C18.9961 19.8551 19.0006 19.8297 18.9999 19.8043C18.976 18.6633 18.739 17.5534 18.2938 16.5055ZM10 11.0251C8.90134 11.0251 7.8673 10.5979 7.08938 9.82211C6.31146 9.04636 5.88301 8.0152 5.88301 6.9196C5.88301 5.824 6.31146 4.79284 7.08938 4.01709C7.8673 3.24133 8.90134 2.81407 10 2.81407C11.0987 2.81407 12.1327 3.24133 12.9106 4.01709C13.6885 4.79284 14.117 5.824 14.117 6.9196C14.117 8.0152 13.6885 9.04636 12.9106 9.82211C12.1327 10.5979 11.0987 11.0251 10 11.0251Z"
                fill="#9494E1"/>
        </svg>
    );
};

export default UserIcon;