import React from 'react';

const CrossMenuIcon = () => {
    return (
        <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5.30762" width="29" height="3" rx="1.5" transform="rotate(45 5.30762 0)" fill="#000057"/>
            <rect x="3.18628" y="20.6924" width="29" height="3" rx="1.5" transform="rotate(-45 3.18628 20.6924)"
                  fill="#000057"/>
            <rect x="3.18628" y="20.5061" width="29" height="3" rx="1.5" transform="rotate(-45 3.18628 20.5061)"
                  fill="#000057"/>
        </svg>
    );
};

export default CrossMenuIcon;