import React, {FC, useState} from 'react';
import NotifyError from "../Components/Notify/NotifyError";

const ResetPassword: FC<{
    onReset: (email: string) => void;
}> = ({onReset}) => {
    const [email, setEmail] = useState<string>("");
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        const sendMail = () => {
            if (!re.test(String(email).toLowerCase())) {
                NotifyError("Неправильный Email");
        } else {
                onReset(email);
            }
    }

    return (
        <div className="reset__mail">
            <p className="reset__mail__subtitle">Восстановление доступа</p>
            <p className="reset__mail__text">Мы пришлем вам письмо </p>
            <p className="reset__mail__text">с инструкцией по изменению пароля</p>
            <div className="reset__mail__input__container">
                <label className="reset__mail__label" htmlFor=""> Почта</label>
                <span className="reset__input__email__icon"/>
                <input onChange={(e) => setEmail(e.target.value)} type="email" value={email}
                       placeholder="введите свой email" className="reset__mail__input"/>
            </div>
            <div className="reset__mail__btn__container">
                <button className="reset__mail__btn" onClick={() => sendMail()}>Продолжить
                </button>
            </div>
        </div>
    );
};

export default ResetPassword;