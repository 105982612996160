import React from 'react';

const CrossPopupIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
                  d="M1.63279 0.606583L0.606563 1.63281L7.27699 8.30324L0.606445 14.9738L1.63267 16L8.30322 9.32947L14.9737 16L16 14.9738L9.32945 8.30324L15.9999 1.63283L14.9736 0.606604L8.30322 7.27701L1.63279 0.606583Z"
                  fill="white"/>
        </svg>

    );
};

export default CrossPopupIcon;