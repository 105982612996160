import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {RegisterData} from "../../api/users";
import GoogleAuthBtn from "./GoogleAuthBtn";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import NotifyError from "../Notify/NotifyError";
import {Link, useLocation} from "react-router-dom";
import VisiblePassword from "../icons/VisiblePassword";
import NoneVisiblePassword from "../icons/NoneVisiblePassword";
import useAuth from "../../hooks/useAuth";
// import useDefaults from "../../hooks/useDefaults";
// import {useQueryParams} from "../QueryParams";

type RegisterProps = {
    handleRegister: (data: RegisterData) => void,
    page?: string,
    popup?: boolean;
}

type UserSubmitForm = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;

};

const Register: FC<RegisterProps> = ({handleRegister, page, popup}) => {
    const [registerSection, setRegisterSection] = useState<boolean>(false);
    const [pageFbRegister, setPageFbRegister] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
    const [checkedI, setChecked] = useState<boolean>(true);
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Имя обязательно'),
        email: Yup.string()
            .required('Email обязательный')
            .email('Email является недействительным'),
        password: Yup.string()
            .required('Пароль обов\'язковий')
            .min(6, 'Пароль должен содержать не менее 6 символов')
            .max(40, 'Пароль должен содержать не больше 40 символов'),
    });
    const location = useLocation();
    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<UserSubmitForm>({
        resolver: yupResolver(validationSchema)
    });
    let dataSessionStorage = sessionStorage.getItem('recreative');
    const onRegisterClick = async () => {
        try {
            await handleRegister({
                name,
                email,
                password
            });
            if (dataSessionStorage === "true") {
                window.location.href = "/thankRe"
            }else{
                window.location.href = "/"
            }
        } catch (e) {
            NotifyError('такий email вже існує, спробуйте інший');
        }
    }
    const {login} = useAuth();
    const onLoginClick = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        try {
            await login({
                email,
                password
            })
        } catch (e) {
            NotifyError('неправильний email или пароль');
        }
    };
    useEffect(() => {
        if (popup === false) {
            setEmail('');
            setPassword('');
        }
    }, [popup]);
    useEffect(() => {
        if (location.pathname === "/fbregister/" || location.pathname === "/fbregister") {
            setPageFbRegister(true)
        }
    }, [location])
    return (
        <form onSubmit={!registerSection ? handleSubmit(onRegisterClick) : onLoginClick}
              className={page === "fb" ? "registration-login fb" : "registration-login"}>
            {pageFbRegister ?
                <div className="section__choose__container">
                    <p className={registerSection ? "section__choose__register fb " : "section__choose__register active fb"}
                       onClick={() => setRegisterSection(false)}>Регистрация <span
                        className={registerSection ? "section__choose__register__line fb" : "section__choose__register__line active fb"}/>
                    </p>
                    <p className={registerSection ? "section__choose__login active fb" : "section__choose__login fb"}
                       onClick={() => setRegisterSection(true)}>Вход <span
                        className={!registerSection ? "section__choose__login__line fb" : "section__choose__login__line active fb"}/>
                    </p>
                </div>
                :
                <div className="section__choose__container">
                    <p className={registerSection ? "section__choose__register " : "section__choose__register active"}
                       onClick={() => setRegisterSection(false)}>Регистрация <span
                        className={registerSection ? "section__choose__register__line " : "section__choose__register__line active"}/>
                    </p>
                    <p className={registerSection ? "section__choose__login active" : "section__choose__login "}
                       onClick={() => setRegisterSection(true)}>Вход <span
                        className={!registerSection ? "section__choose__login__line " : "section__choose__login__line active"}/>
                    </p>
                </div>
            }
            {!registerSection &&
                <div className={page === "fb" ? "input__container fb" : "input__container"}>
                    <label className={page === "fb" ? 'label__inp fb' : 'label__inp'} htmlFor="name">
                        Имя
                    </label>
                    <span className={page === "fb" ? "input__name__icon fb" : 'input__name__icon'}/>
                    <input className={`input ${errors.name ? 'is-invalid' : ''} ${page === "fb" && "fb"} `} id="name"
                           type="text" placeholder='введите свое имя' value={name}
                           {...register('name')}
                           onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)}/>
                    <div className="invalid__feedback">{errors.name?.message}</div>
                </div>
            }
            <div className={page === "fb" ? "input__container fb" : "input__container"}>
                <label className={page === "fb" ? 'label__inp fb' : 'label__inp'} htmlFor="email">
                    Почта
                </label>
                <span className={page === "fb" ? "input__email__icon fb" : "input__email__icon"}/>
                <input className={`input ${errors.email ? 'is-invalid' : ''} ${page === "fb" && "fb"}`} id="email"
                       type="text" placeholder='введите свой email' value={email}
                       {...register('email')}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}/>
                <div className="invalid__feedback">{errors.email?.message}</div>
            </div>
            <div className={page === "fb" ? "input__container fb" : "input__container"}>
                <label className={page === "fb" ? 'label__inp fb' : 'label__inp'} htmlFor="password">
                    Пароль
                </label>
                <span className={page === "fb" ? "input__password__icon fb" : "input__password__icon"}/>
                <input className={`input ${errors.password ? 'is-invalid' : ''} ${page === "fb" && "fb"}`}
                       id="password"
                       type={!visiblePassword ? "password" : "text"} placeholder='введите пароль' value={password}
                       {...register('password')}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}/>
                {visiblePassword ?
                    <div onClick={() => setVisiblePassword(!visiblePassword)} className="input__visible__icon">
                        <VisiblePassword/>
                    </div>
                    :
                    <div onClick={() => setVisiblePassword(!visiblePassword)} className="input__nonevisible__icon">
                        <NoneVisiblePassword/>
                    </div>
                }
                <div className="invalid__feedback">{errors.password?.message}</div>
            </div>
            {!registerSection &&
                <div className="registration-login__checkbox__container">
                    <input type="checkbox" checked={checkedI}
                           className={page === "fb" ? "registration-login__checkbox fb" : "registration-login__checkbox"}
                           defaultChecked={checkedI} onChange={() => setChecked(!checkedI)}/>
                    <label onClick={() => setChecked(!checkedI)}
                           className={page === "fb" ? "registration-login__checkbox__text fb" : "registration-login__checkbox__text"}>Я
                        согласен получать информацию по email и sms</label>
                </div>
            }
            <div className="registration-login__btn__container">
                <button disabled={!checkedI}
                        className={page === "fb" ? "registration-login__btn__continue fb" : "registration-login__btn__continue"}
                        type="submit">
                    {!registerSection ?
                        "Регистрация"
                        :
                        "Войти"
                    }
                </button>
                {!registerSection ?
                    <p className={page === "fb" ? "registration-login__btn__container__text fb" : "registration-login__btn__container__text"}>или</p>
                    :
                    <Link to="/reset-password"
                          className={pageFbRegister ? "registration-login__reset__password fb" : "login__reset__password registration-login__reset__password"}>забыли
                        пароль ?</Link>
                }
                <GoogleAuthBtn disabled={!checkedI} className="registration-login__btn__google"
                               label='Вход через Google'/>
            </div>
        </form>
    );
};

export default Register;