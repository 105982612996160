import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__sub__container">
                <h3 className="footer__sub__title__number">18+</h3>
                <p className="footer__text__copyright">COPYRIGHT © 2022
                    CLUB CASINO</p>
            </div>
            <div className="footer__sub__container">
                <h3 className="footer__sub__title">ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ</h3>
                <p className="footer__text">Вы должны убеждаться, что соблюдаете все возрастные и другие нормативные
                    требований перед тем, как войти в казино или поставить ставку. информация на этом сайте представлена
                    только для игроков в казино.</p>
            </div>
            <div className="footer__sub__container">
                <h3 className="footer__sub__title">ОТВЕТСТВЕННАЯ ИГРА</h3>
                <p className="footer__text">Сайт Club Casino не проводит игры на деньги и не несет ответственности ни за
                    какие потери от игры в казино Мы всегда призываем использовать соответствующую азартную игру.
                </p>
            </div>
        </footer>
    );
};

export default Footer;