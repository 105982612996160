import React from 'react';

const VisiblePassword = () => {
    return (
        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.52055 0C6.58764 0 4.85715 0.922822 3.50238 1.97538C2.13965 3.03413 1.07393 4.2885 0.452653 5.09931C0.159245 5.47809 0 5.94366 0 6.42284C0 6.90201 0.159235 7.36757 0.452626 7.74634C1.0739 8.55715 2.13963 9.81154 3.50238 10.8703C4.85715 11.9229 6.58764 12.8457 8.52055 12.8457C10.4535 12.8457 12.1839 11.9229 13.5387 10.8703C14.9025 9.81071 15.969 8.55508 16.59 7.74432C17.1913 6.95909 17.1904 5.88663 16.5899 5.10125C15.9689 4.2905 14.9025 3.03498 13.5387 1.97538C12.1839 0.922822 10.4535 0 8.52055 0ZM1.72586 6.07771C2.30346 5.3237 3.27437 4.18591 4.48752 3.24338C5.70972 2.29382 7.09592 1.60571 8.52055 1.60571C9.94518 1.60571 11.3314 2.29382 12.5536 3.24338C13.7665 4.18575 14.7373 5.32331 15.3149 6.07732C15.4754 6.28742 15.4748 6.55948 15.3152 6.7681C14.7375 7.52211 13.7667 8.65983 12.5536 9.60231C11.3314 10.5519 9.94518 11.24 8.52055 11.24C7.09592 11.24 5.70972 10.5519 4.48752 9.60231C3.27437 8.65978 2.30347 7.52198 1.72588 6.76797L1.72254 6.76368C1.64682 6.6662 1.60571 6.54628 1.60571 6.42284C1.60571 6.29941 1.64683 6.1795 1.72256 6.08202L1.72586 6.07771Z"
                  fill={window.location.pathname === "/fbregister" || window.location.pathname === "/fbregister/" ? "white" : "#000057"} fillOpacity="0.5"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.5205 4.81714C7.63369 4.81714 6.91479 5.53604 6.91479 6.42285C6.91479 7.30966 7.63369 8.02856 8.5205 8.02856C9.40731 8.02856 10.1262 7.30966 10.1262 6.42285C10.1262 5.53604 9.40731 4.81714 8.5205 4.81714ZM5.30908 6.42285C5.30908 4.64923 6.74688 3.21143 8.5205 3.21143C10.2941 3.21143 11.7319 4.64923 11.7319 6.42285C11.7319 8.19647 10.2941 9.63427 8.5205 9.63427C6.74688 9.63427 5.30908 8.19647 5.30908 6.42285Z"
                  fill={window.location.pathname === "/fbregister" || window.location.pathname ===  "/fbregister/" ? "white" : "#000057"} fillOpacity="0.5"/>
        </svg>

    );
};

export default VisiblePassword;