import React from 'react';
// @ts-ignore
import email from "../../assets/thank/email.png";

const ThankPageFb = () => {
    return (
        <div className="thankPage">
            <div className="thankPage__container">
                <p className="thankPage__title">Письмо отправлено Вам на почту</p>
                <img className="thankPage__img" src={email} alt="конверт з письмом "/>
                <p className="thankPage__text">
                    Мы отправили Вам сообщение для завершения регистрации.
                </p>
                <p className="thankPage__text">Если вы не получили сообщения для активации в течение 2 минут:</p>
                <ul className="thankPage__list__container">
                    <li className="thankPage__list">Проверьте, пожалуйста, папки со <span
                        className="thankPage__list__span">спамом</span> или <span
                        className="thankPage__list__span">рекламой</span> .
                    </li>
                    <li className="thankPage__list">
                        Убедитесь, что вы ввели свой адрес правильно.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ThankPageFb;