import React, {FC, Fragment, useEffect, useLayoutEffect, useState} from 'react';
import CardOffer from "../Components/CardOffer";
import {Link} from "react-router-dom";


interface IPromokod {
    state: any,
    updateOffers: any
}

const Promokod: FC<IPromokod> = ({state, updateOffers}) => {
    const [noneOffers, setNoneOffers] = useState(false);

    useLayoutEffect(() => {
        if (!state[0]) {
            setNoneOffers(true)
        }
    }, [state]);
    const ComponentNonePromo = () => {
        return (
            <div className="offer__none__container">
                <p className="offer__none__title">Вот черт !</p>
                <p className="offer__none__text">К сожалению сейчас нет </p>
                <p className="offer__none__text">доступных промокодов</p>
                <Link className="offer__none__link" to="/">На главную</Link>
            </div>
        )
    }
    return (
        <>
            <div className="title__bg">
                <p className="title__rozdil">промокоды</p>
            </div>
            {noneOffers ?
                <ComponentNonePromo/>
                :
                <div className="card__container">
                    {state?.map((item: any, index: number,) => (
                        <Fragment key={index + item.title}>
                            <CardOffer item={item} updateOffers={updateOffers}/>
                        </Fragment>
                    ))}
                </div>
            }
        </>
    );
};

export default Promokod;