import React, {FC} from 'react';
import {slide as Menu} from "react-burger-menu";
import CrossMenuIcon from "./icons/CrossMenuIcon";
import {Link, useLocation} from "react-router-dom";
import MainIcon from "./icons/MainIcon";
import LoginIcon from "./icons/LoginIcon";
import PromoIcon from "./icons/PromoIcon";
import AllCasinoIcon from "./icons/AllCasinoIcon";
import BurgerIcons from "./icons/BurgerIcons";
import useAuth from "../hooks/useAuth";
import UserIcon from "./icons/UserIcon";


interface SideBarProps {
    pageWrapId: string,
    outerContainerId: string,
    isOpen: boolean,
    removeBtnPopup: () => void,
    AddPopupBtn?: () => void,
    setIsOpen: (arg0: boolean) => void,
}

const Burger: FC<SideBarProps> = ({isOpen, setIsOpen, pageWrapId, outerContainerId, removeBtnPopup}) => {
    // const [isOpen, setIsOpen] = useState(false);
    const {isAuth} = useAuth();
    const location = useLocation();
    const handleIsOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Menu
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
            right pageWrapId={pageWrapId} outerContainerId={outerContainerId}
            customCrossIcon={<CrossMenuIcon/>} customBurgerIcon={<BurgerIcons/>}>
            <div className="burger__top__container">
                <p className="burger__top__text">МЕНЮ</p>
            </div>
            <div className="burger__links__container">
                {!isAuth ?
                    <Link onClick={() => {
                        handleIsOpen()
                        removeBtnPopup()
                    }} to="/login" className="burger__link__wrapper">
                        <LoginIcon/>
                        <p className="burger__link">
                            Вход
                        </p>
                    </Link>
                    :
                    <Link onClick={handleIsOpen} to="/profile" className="burger__link__wrapper">
                        <UserIcon/>
                        <p className="burger__link">
                            Профиль
                        </p>
                    </Link>
                }
                <Link className="burger__link__wrapper" onClick={() => {
                    handleIsOpen()
                    removeBtnPopup()
                }} to="/">
                    <MainIcon/>
                    <p className="burger__link">
                        Главная
                    </p>
                </Link>
                {location.pathname !== "/register" && location.pathname !== "/login" &&
                    <>
                        <div className="burger__link__wrapper">
                            <PromoIcon/>
                            {/*{isAuth ?*/}
                                <Link onClick={handleIsOpen} className="burger__link" to="/promokod">
                                    Промокоды <span className="burger__link__new">new</span>
                                </Link>
                                {/*:*/}
                            {/*    <div onClick={() => {*/}
                            {/*        AddPopupBtn()*/}
                            {/*        handleIsOpen()*/}
                            {/*    }} className="burger__link__btn burger__link">*/}
                            {/*        Промокоди <span className="burger__link__new">new</span>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        <Link className="burger__link__wrapper" to="/allcasino" onClick={() => {
                            handleIsOpen()
                            removeBtnPopup()
                        }}>
                            <AllCasinoIcon/>
                            <p className="burger__link">
                                Все казино
                            </p>
                        </Link>
                    </>
                }
            </div>
        </Menu>
    );
};

export default Burger;