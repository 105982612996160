import React, {useEffect} from 'react';
import General from "../pages/General";
// import {useQueryParams} from "../Components/QueryParams";
import useDefaults from "../hooks/useDefaults";
import useOffers from "../hooks/useOffers";


const ContainerGeneral = () => {
    // let {pid, sub1} = useQueryParams();
    const {defaults} = useDefaults();
    const {offers, setInitialOffers} = useOffers();

    // useEffect(() => {
    //     const lsPid = window.localStorage.getItem('pid');
    //     const lsSub1 = window.localStorage.getItem('sub1');
    //
    //     if(defaults.pid){
    //         window.localStorage.setItem('pid', Boolean(pid) ? pid : Boolean(lsPid) ? lsPid : defaults.pid);
    //         window.localStorage.setItem('sub1', Boolean(sub1) ? sub1 : Boolean(lsSub1) ? lsSub1 : defaults.sub1);
    //     }
    // }, [defaults, pid, sub1]);
    useEffect(() => {
        if (!defaults?.showOffers) return;
        setInitialOffers("showOffers").then(() => {
        })
    }, [defaults]);

    const likeSetOffersShowOffers = () => {
        setInitialOffers("showOffers").then(() => {
        });
    }

    return (
        <General state={offers} updateOffers={likeSetOffersShowOffers}/>
    );
};

export default ContainerGeneral;