import React from 'react';
import ReactGA from "react-ga4";
import useAuth from "./useAuth";

const Ga = () => {
    const {isAuth, user: {emailVerified}} = useAuth();
    const LogoutProfile = () => {
        ReactGA.event({
            category: 'logout',
            action: `click on copy logout`
        });
    };
    const ClickOnOfferGoogleA = (offer: any) => {
        if ( emailVerified){
            ReactGA.event({
                category: 'offer',
                action: `${offer} click on з верифікацією`
            });
        } else if(isAuth){
            ReactGA.event({
                category: 'offer',
                action: `${offer} click on без верифікації, але з реєстрацією`
            });
        }else{
            ReactGA.event({
                category: 'offer',
                action: `${offer} click on без реєстрації`
            });
        }
    };

    const CopyPromoAuthGoogleA = (offer: any) => {
        ReactGA.event({
            category: 'promokod auth',
            action: `${offer} click on copy promokod`
        });
    };
    const CopyPromoNoAuthNoRegisterGoogleA = (offer: any) => {
        ReactGA.event({
            category: 'promokod no auth',
            action: `${offer} click on copy promokod без реєстрації `
        });
    };
    const CopyPromoNoAuthNoVerifyGoogleA = (offer: any) => {
        ReactGA.event({
            category: 'promokod no auth',
            action: `${offer} click on copy promokod без верифікації, але з реєстрацією `
        });
    };
    return {
        LogoutProfile,
        ClickOnOfferGoogleA,
        CopyPromoAuthGoogleA,
        CopyPromoNoAuthNoRegisterGoogleA,
        CopyPromoNoAuthNoVerifyGoogleA
    }
}
export default Ga;