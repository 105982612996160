import React, {useEffect, useState} from 'react';
// @ts-ignore
import email from "../assets/thank/email.png";
import ReactHelmet from "../Components/ReactHelmet";

const ThankRecreative = () => {
    const [number, setNumber] = useState(3);
    useEffect(() => {
        const interval = setInterval(() => {
            if (number <= 3 && number !== 0) {
                // @ts-ignore
                setNumber(prevState => {
                    if (prevState <= 3) {
                        return prevState - 1
                    }

                })
            }
            if (number === 0) {
                 window.location.href = '/';
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [number])
    return (
        <div className="thankPage">
            <ReactHelmet/>
            <div className="thankPage__container">
                <p className="thankPage__title">Спасибо за регистрацию</p>
                <img className="thankPage__img" src={email} alt="конверт з письмом "/>
                <p className="thankPage__text">
                    Вас будет перенаправлен на главную страницу через {number}
                </p>
            </div>
        </div>
    );
};

export default ThankRecreative;