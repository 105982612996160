import React, {FC, Fragment} from 'react';
import CardOffer from "../Components/CardOffer";
import IOffers from "../interface/IOffers";

const AllCasino:FC<IOffers> = ({state, updateOffers}) => {
    return (
        <>
            <div className="title__bg">
                <p className="title__rozdil">Все казино</p>
            </div>
            <div className="card__container">
                {state?.map((item:any, index:number,)=>(
                    <Fragment key={index + item.title}>
                        <CardOffer item={item} updateOffers={updateOffers}/>
                    </Fragment>
                ))}
            </div>
        </>
    );
};

export default AllCasino;