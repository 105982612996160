import React, {FC, Fragment} from 'react';
import LogoutIcon from "../Components/icons/LogoutIcon";
// @ts-ignore
import UserIcon from "../assets/Profile/user-profile-icon.png";
import {Link} from "react-router-dom";
import GA from "../hooks/GA";

interface Iuser {
    user: {
        name: string,
        email: string,
        emailVerified: boolean
    },
    logout: () => void
}

const Profile: FC<Iuser> = ({user, logout}) => {
    const {LogoutProfile} = GA();
    return (
        <Fragment>
            <div className="profile__name__container">
                <img className="profile__name__img" src={UserIcon} alt="user profile icon"/>
                <div className="profile__name__text__container">
                    <p className="profile__name__text__subtitle">Профиль</p>
                    <p className="profile__name__text__name">{user.name}</p>
                </div>
            </div>
            <div className="profile__flex__container">

                <div className="profile__email">
                    <p className="profile__email__subtitle">Ваша почта:</p>
                    <p className="profile__email__text">{user.email}</p>
                </div>

                <Link to="/" className="profile__btn" onClick={() => {
                    logout()
                    LogoutProfile()
                }}>Выйти з профиля
                    <span className="profile__btn__icon">
                    <LogoutIcon/>
                </span>
                </Link>
            </div>
        </Fragment>
    );
};

export default Profile;