export const ADD_POPUP = "ADD_POPUP"
export const REMOVE_POPUP = "REMOVE_POPUP"

export const initialState = {popup: false,text:"",allText:""}


export const popupReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case"ADD_POPUP":
            return {...state, popup: true,text:action.payload,allText:action.allText}
        case"REMOVE_POPUP":
            return {...state, popup: false}
        default:
            return state;
    }
}

export function addPopup(text:string,allText:string) {
    const action: any = {
        type: ADD_POPUP,
        payload:text,
        allText:allText
    };
    return action;
}
export function removePopup() {
    const action: any = {
        type: REMOVE_POPUP,
    };
    return action;
}