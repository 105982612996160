import React from 'react';

const MainIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.85897 1.05959C9.45401 1.14689 9.09956 1.34592 8.70237 1.70896C8.50694 1.88764 6.75358 3.54342 4.80604 5.38853C1.52178 8.50006 1.25627 8.76115 1.14362 8.98994C0.786508 9.71541 1.11186 10.5707 1.87069 10.9013C1.97307 10.9459 2.17155 10.9761 2.44627 10.989L2.86557 11.0087L2.87596 14.5555C2.8862 18.0687 2.88709 18.1033 2.96686 18.2102C3.01116 18.2695 3.09592 18.3543 3.15526 18.3986L3.26315 18.4791H10.3668H17.4704L17.5783 18.3986C17.6376 18.3543 17.7224 18.2695 17.7667 18.2102C17.8465 18.1033 17.8474 18.0688 17.8576 14.5505L17.868 10.9987H18.1959C18.8871 10.9987 19.3271 10.7415 19.5994 10.1783C19.7077 9.95459 19.7222 9.8849 19.7222 9.59158C19.7222 9.29447 19.7085 9.2308 19.5943 8.99889C19.4731 8.75275 19.2777 8.56029 15.886 5.34736C13.9167 3.48193 12.1637 1.82709 11.9904 1.6699C11.3612 1.09912 10.6373 0.891815 9.85897 1.05959ZM10.8715 2.39053C11.1137 2.52959 18.4904 9.50658 18.4912 9.5974C18.4923 9.72361 18.394 9.74338 17.7026 9.7558C16.9491 9.76932 16.9081 9.78103 16.7169 10.0371C16.6371 10.144 16.6362 10.1785 16.6259 13.6968L16.6156 17.2487H15.0558H13.496L13.4824 15.4225C13.4708 13.8767 13.4589 13.5632 13.405 13.3809C13.1558 12.5379 12.7277 11.9409 12.0599 11.5049C11.5277 11.1575 11.0473 11.0199 10.3668 11.0199C9.6876 11.0199 9.20405 11.1581 8.67901 11.5022C8.00041 11.947 7.5792 12.5329 7.32858 13.3809C7.27467 13.5632 7.2628 13.8767 7.2512 15.4225L7.23752 17.2487H5.67776H4.11799L4.10764 13.6968C4.09737 10.1785 4.09651 10.144 4.0167 10.0371C3.82616 9.78189 3.78463 9.76994 3.01471 9.74865C2.4037 9.73174 2.30299 9.7201 2.26655 9.66217C2.23397 9.61041 2.2419 9.57322 2.30147 9.49826C2.42588 9.34166 9.66014 2.50978 9.78741 2.42869C10.1253 2.21334 10.5376 2.19885 10.8715 2.39053ZM10.8144 12.3085C11.4881 12.484 12.0071 13.0046 12.1835 13.682C12.2308 13.8634 12.2418 14.2216 12.2418 15.5772V17.2487H10.3668H8.49178V15.5772C8.49178 14.2216 8.5028 13.8634 8.55006 13.682C8.81295 12.6726 9.83416 12.0531 10.8144 12.3085Z"
                  fill="#9EE69D"/>
        </svg>

    );
};

export default MainIcon;