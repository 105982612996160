import React from 'react';
import Profile from "../pages/Profile";
import useAuth from "../hooks/useAuth";

const ContainerProfile = () => {
    const {logout, isAuth, user} = useAuth();
    return (
        <>
            {isAuth && <Profile user={user} logout={logout}/>}
        </>
    );
};

export default ContainerProfile;