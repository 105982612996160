import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const NotifySuccess = (text:string) => {
    toast.success(text, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    return toast
};


export default NotifySuccess;