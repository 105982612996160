import React from "react";
import RegisterComponent from "../Components/Auth/Register";
import useAuth from "../hooks/useAuth";
import {Redirect} from "react-router-dom";

const Register = () => {
    const {register, isAuth} = useAuth();

    if (isAuth) return <Redirect to='/'/>

    return (
        <>
            {!isAuth && <RegisterComponent handleRegister={register}/>}
        </>
    );
};

export default Register;
