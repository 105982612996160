import React from 'react';

const LoginIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.74221 0.0723142C7.02674 0.248174 6.43541 0.718642 6.10482 1.37513C5.83193 1.917 5.83674 1.86067 5.83674 4.50899V6.89181L5.94521
                  7.0763C6.13842 7.40501 6.52807 7.55993 6.89975 7.45587C7.13123 7.39106 7.38236 7.14892 7.44885 6.92634C7.48439 6.80735 7.49689 6.19028
                  7.49689 4.55204C7.49689 2.13642 7.49314 2.17985 7.72357 1.93704C7.7815 1.87603 7.89455 1.79231 7.97475 1.75106C8.11654 1.67813 8.25369
                  1.67634 12.9586 1.68626L17.7965 1.69649L17.9495 1.80329C18.0336 1.862 18.1463 1.97497 18.2 2.05435L18.2977 2.19856V9.99727V17.796L18.2
                  17.9402C18.1463 18.0196 18.0336 18.1325 17.9495 18.1913L17.7965 18.2981L12.9586 18.3083C8.25369 18.3182 8.11654 18.3164 7.97475 18.2435C7.89455
                  18.2022 7.7815 18.1185 7.72357 18.0575C7.49314 17.8147 7.49689 17.8581 7.49689 15.4425C7.49689 13.8043 7.48439 13.1872 7.44885 13.0682C7.38236
                  12.8456 7.13123 12.6035 6.89975 12.5387C6.52807 12.4346 6.13842 12.5895 5.94521 12.9183L5.83674 13.1027V15.4856C5.83674 18.1339 5.83193 18.0775
                  6.10482 18.6194C6.35182 19.1099 6.74428 19.4966 7.24283 19.7408C7.7842 20.006 7.59111 19.9974 12.9347 19.9966C18.3145 19.9958 18.0384 20.0093
                  18.6284 19.7184C19.2691 19.4026 19.7928 18.7257 19.9387 18.0246C20.0241 17.6138 20.0241 2.38079 19.9387 1.96993C19.7928 1.26888 19.2691 0.591963
                  18.6284 0.276103C18.0374 -0.0152249 18.3211 -0.00163111 12.8875 0.000829832C8.36693 0.00286108 8.00436 0.00790014 7.74221 0.0723142ZM10.5633
                  6.7247C10.3744 6.79165 10.2381 6.90513 10.1367 7.07989C10.0017 7.31235 9.97826 7.49157 10.0504 7.73813C10.1054 7.92563 10.1703 8.00509 10.6987 8.53192C11.0217 8.85395 11.286 9.13079 11.286 9.14716C11.286 9.16356 8.88584 9.17696 5.95236 9.17696C0.780762 9.17696 0.614395 9.17923 0.473848 9.25149C-0.157949 9.57649 -0.157949 10.4181 0.473848 10.7431C0.614395 10.8153 0.780762 10.8176 5.95236 10.8176C8.88584 10.8176 11.286 10.831 11.286 10.8474C11.286 10.8638 11.0221 11.1406 10.6995 11.4626C10.0444 12.1166 9.97475 12.2298 10.0047 12.5916C10.0398 13.0147 10.3854 13.3308 10.8122 13.3304C10.9437 13.3303 11.087 13.3026 11.1688 13.2615C11.3501 13.1704 13.9652 10.5656 14.0621 10.3797C14.1638 10.1842 14.164 9.81103 14.0624 9.61485C14.0212 9.53517 13.3752 8.86079 12.6269 8.11626C11.4851 6.98024 11.2413 6.75579 11.1102 6.72001C10.9188 6.66782 10.7188 6.66954 10.5633 6.7247Z"
                  fill="#9494E1"/>
        </svg>

    );
};

export default LoginIcon;